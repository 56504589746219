/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import React, { useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { token, useThemeObserver } from '@atlaskit/tokens';

export type Background = 'white' | 'blue' | 'neutral' | 'checkered';

const backgroundColorStyles = {
	white: css({
		backgroundColor: token('elevation.surface'),
	}),

	blue: css({
		backgroundColor: token('color.background.brand.bold'),
	}),

	neutral: css({
		backgroundColor: token('color.background.neutral.hovered'),
	}),
	checkered: css({
		backgroundColor: 'var(--ds-surface, white)',
		backgroundImage: `
    linear-gradient(
      45deg,
      ${token('elevation.surface.sunken')} 25%,
      transparent 25%
    ),
    linear-gradient(
      135deg,
      ${token('elevation.surface.sunken')} 25%,
      transparent 25%
    ),
    linear-gradient(
      45deg,
      transparent 75%,
      ${token('elevation.surface.sunken')} 75%
    ),
    linear-gradient(
      135deg,
      transparent 75%,
      ${token('elevation.surface.sunken')} 75%
    );`,
	}),
};

type ExampleShowcaseProps = {
	backgroundColor?: Background;
	children: React.ReactNode;
};

const toggleStyles = xcss({
	paddingBlock: 'space.100',
	userSelect: 'none',
});

const customThemeStyles = css({
	backgroundPosition: '0 0, 10px 0, 10px -10px, 0px 10px',
	backgroundSize: '20px 20px',
	borderBlockEnd: `${token('border.width', '1px')} solid ${token('color.border')}`,
	borderRadius: `${token('border.radius', '3px')} ${token('border.radius', '3px')} 0 0`,
	color: token('color.text'),
	paddingBlockEnd: token('space.300', '24px'),
	paddingInlineEnd: token('space.075', '6px'),
	paddingInlineStart: token('space.150', '12px'),
});

/**
 * __Example showcase__
 *
 * An example showcase displays an example with code and link to codesandbox.
 *
 */
const ExampleShowcase = ({ backgroundColor = 'checkered', children }: ExampleShowcaseProps) => {
	const currentTheme = useThemeObserver();
	const { colorMode: currentColorMode = 'light' } = currentTheme;

	const [theme, setTheme] = useState(currentColorMode);

	useEffect(() => {
		setTheme(currentColorMode);
	}, [currentColorMode]);

	const toggleTheme = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.checked) {
				setTheme('dark');
			} else {
				setTheme('light');
			}
		},
		[setTheme],
	);
	return (
		<div
			role="group"
			css={[backgroundColorStyles[backgroundColor], customThemeStyles]}
			data-custom-mode="true"
			data-color-mode={theme}
		>
			<Inline alignBlock="center" alignInline="end" space="space.025" xcss={toggleStyles}>
				<Text>Dark theme</Text>
				<Toggle isChecked={theme === 'dark'} onChange={toggleTheme} label="Enable dark mode" />
			</Inline>
			<Box paddingInlineEnd="space.075">{children}</Box>
		</div>
	);
};

export default ExampleShowcase;
