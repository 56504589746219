/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ReactNode, useContext } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, type Interpolation, jsx, type Theme } from '@emotion/react';
import upperFirst from 'lodash/upperFirst';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import {
	getBorderForValue,
	getBoxShadow,
	getTextColorForBackground,
} from '../../../color-palette-ramps/color-detection';
import { tokenColorModeValues } from '../theme-color-mode-styles';
import type { TransformedTokenGrouped, TransformedTokenMerged } from '../types';
import { normaliseThemeColorMode } from '../utils';

import { ThemeBoundaryContext } from './token-button-value';
import { type TokenValue } from './token-definition';

const baseTokenButtonUiStyles = css({
	display: 'flex',
	boxSizing: 'border-box',
	width: '100%',
	minHeight: '1.5rem',
	padding: `${token('space.025', '2px')} ${token('space.150', '12px')}`,
	alignItems: 'center',
	justifyContent: 'center',
	background: 'none',
	borderRadius: token('border.radius', '3px'),
	color: token('color.text'),
	font: token('font.body.UNSAFE_small'),
	fontFamily: token(
		'font.family.code',
		`'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier, monospace`,
	),
	'&:hover, &:focus': {
		background: token('color.background.neutral.hovered'),
	},
	'&:active': {
		background: token('color.background.neutral.pressed'),
	},
});

const subtleStyles = css({
	backgroundColor: token('color.background.neutral'),
});

const ghostStyles = css({
	justifyContent: 'flex-start',
	overflowWrap: 'break-word',
	paddingInlineStart: token('space.050', '4px'),
	textAlign: 'left',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&[data-is-hovered="true"]': {
		background: token('color.background.neutral'),
		'&:hover, &:focus': {
			background: token('color.background.neutral'),
		},
	},
});

const disableHoverStyles = css({
	background: 'none',
	'&:hover, &:focus, &:active': {
		background: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&[data-is-hovered="true"]': {
		background: 'none',
		'&:hover, &:focus': {
			background: 'none',
		},
	},
});

interface TooltipButtonRenderProps {
	isHovered?: boolean;
	children?: ReactNode;
}

interface BaseTokenButtonStyleProps extends TooltipButtonRenderProps {
	className?: string;
}

interface LabelProps extends BaseTokenButtonStyleProps {
	appearance?: 'subtle' | 'none';
	isHoverDisabled?: boolean;
}

const BaseTokenButtonStyle = (props: BaseTokenButtonStyleProps) => {
	const { children, isHovered, className } = props;
	return (
		<span
			css={baseTokenButtonUiStyles}
			data-is-hovered={isHovered}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
		>
			{children}
		</span>
	);
};

const Label = (props: LabelProps) => {
	const { children, appearance = 'none', isHovered, isHoverDisabled, className } = props;

	return (
		<BaseTokenButtonStyle
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			isHovered={isHovered && !isHoverDisabled}
			css={[
				isHoverDisabled && disableHoverStyles,
				appearance === 'subtle' && subtleStyles,
				appearance === 'none' && ghostStyles,
			]}
		>
			{children}
		</BaseTokenButtonStyle>
	);
};

type ColorTokenCategoryProps = Pick<TransformedTokenMerged, 'value'> & {
	children?: ReactNode;
};

const Color = (props: ColorTokenCategoryProps) => {
	const { children, value } = props;
	const applyTheme = useContext(ThemeBoundaryContext);

	const backgroundCss = {
		backgroundColor: token('elevation.surface'),
		backgroundImage: `linear-gradient(
          0deg,
          ${value} 0%,
          ${value} 100%
        ),
        linear-gradient(
          45deg,
          ${token('elevation.surface.sunken')} 25%,
          transparent 25%
        ),
        linear-gradient(
          135deg,
          ${token('elevation.surface.sunken')} 25%,
          transparent 25%
        ),
        linear-gradient(
          45deg,
          transparent 75%,
          ${token('elevation.surface.sunken')} 75%
        ),
        linear-gradient(
          135deg,
          transparent 75%,
          ${token('elevation.surface.sunken')} 75%
        )`,
		backgroundPosition: '0px 0px, 0px 0px, 0.5rem 0px, 0.5rem -0.5rem, 0px 0.5rem',
		backgroundSize: '100% 100%, 1rem 1rem, 1rem 1rem, 1rem 1rem, 1rem 1rem',
		'&:hover, &:focus': {
			backgroundColor: token('color.background.neutral.hovered'),
		},
		'&:active': {
			backgroundColor: token('color.background.neutral.pressed'),
		},
	};

	const normalisedColorModel = normaliseThemeColorMode(applyTheme);
	const background = tokenColorModeValues['elevation.surface'][normalisedColorModel];

	return (
		<BaseTokenButtonStyle
			css={[
				{
					position: 'relative',
					zIndex: 0,
					...backgroundCss,
					overflow: 'hidden',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					color: getTextColorForBackground(value),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					outline: getBorderForValue(value, background),
					'&:hover, &:focus': {
						...backgroundCss,
					},
					'&:active': {
						...backgroundCss,
					},
				},
			]}
		>
			{children}
		</BaseTokenButtonStyle>
	);
};

const Opacity = (props: ColorTokenCategoryProps) => {
	const { children, value } = props;
	const spanStyles = {
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: 0,
		left: 0,
		opacity: value,
		backgroundColor: token('color.text'),
	};

	return (
		<BaseTokenButtonStyle
			css={[
				{
					position: 'relative',
					zIndex: 0,
					backgroundColor: token('elevation.surface'),
					backgroundImage: `linear-gradient(
          45deg,
          ${token('elevation.surface.sunken')} 25%,
          transparent 25%
        ),
        linear-gradient(
          135deg,
          ${token('elevation.surface.sunken')} 25%,
          transparent 25%
        ),
        linear-gradient(
          45deg,
          transparent 75%,
          ${token('elevation.surface.sunken')} 75%
        ),
        linear-gradient(
          135deg,
          transparent 75%,
          ${token('elevation.surface.sunken')} 75%
        )`,
					backgroundPosition: '0px 0px, 0.5rem 0px, 0.5rem -0.5rem, 0px 0.5rem',
					backgroundSize: '1rem 1rem',
					color: token('color.text'),
					overflow: 'hidden',
					outline: `1px solid ${token('color.border')}`,
					'&:hover, &:focus': {
						backgroundColor: token('elevation.surface.hovered'),
					},
					'&:active': {
						backgroundColor: token('elevation.surface.pressed'),
					},
				},
			]}
		>
			{children}
			<span
				// @ts-ignore - Type '{ width: string; height: string; position: string; top: number; left: number; opacity: any; backgroundColor: "var(--ds-text)"; }' is not assignable to type 'Interpolation<Theme>'
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				css={spanStyles}
			/>
		</BaseTokenButtonStyle>
	);
};

const Elevation = (props: ColorTokenCategoryProps) => {
	const { children, value } = props;

	return (
		<BaseTokenButtonStyle
			css={[
				{
					backgroundColor: token('elevation.surface'),
					color: token('color.text'),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					boxShadow: getBoxShadow(value),
					outline: `1px solid ${token('color.border')}`,
					'&:hover, &:focus': {
						backgroundColor: token('elevation.surface.hovered'),
					},
					'&:active': {
						backgroundColor: token('elevation.surface.pressed'),
					},
				},
			]}
		>
			{children}
		</BaseTokenButtonStyle>
	);
};

const spaceWidthStyles = xcss({
	width: 'size.600',
});

const negativeSpaceStyles = xcss({
	border: `2px dotted ${token('color.border.accent.purple')}`,
});

const Space = ({ value }: ColorTokenCategoryProps) => {
	const isNegative = value[0] === '-';

	return (
		<Box paddingInline="space.050" xcss={spaceWidthStyles}>
			<Box
				backgroundColor={
					// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
					isNegative ? undefined : 'color.background.accent.purple.subtle'
				}
				xcss={isNegative ? negativeSpaceStyles : undefined}
				style={{
					width: isNegative ? value.slice(1) : value,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					height: '24px',
				}}
			/>
		</Box>
	);
};

type TypographyTokenCategoryProps = {
	tokenOriginal: TransformedTokenGrouped;
	tokenValue: TokenValue;
};

const getFontStyles = (
	tokenValue: TokenValue,
	tokenOriginal: TransformedTokenGrouped,
): Interpolation<Theme> => {
	const lastPath = tokenOriginal.path[tokenOriginal.path.length - 1];
	switch (tokenOriginal.attributes.group) {
		case 'typography':
			return {
				// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
				font: tokenValue.value,
				whiteSpace: 'pre-line',
			};
		case 'fontWeight':
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
			return { fontWeight: tokenOriginal.value };
		case 'fontFamily':
			return {
				font: lastPath === 'heading' ? token('font.heading.large') : token('font.body'),
				fontWeight:
					lastPath === 'heading' ? token('font.weight.bold') : token('font.weight.regular'),
				// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
				fontFamily: tokenOriginal.value,
			};
	}
	return {};
};

const typographyTokenNameMap = {
	xxlarge: 'XXL',
	xlarge: 'XL',
	large: 'L',
	medium: 'M',
	small: 'S',
	xsmall: 'XS',
	xxsmall: 'XXS',
};

const getPreviewText = (tokenOriginal: TransformedTokenGrouped) => {
	let previewText = upperFirst(
		tokenOriginal.cleanName
			.replace('font.', '')
			.replace('heading.', 'Heading ')
			.replace('body.', 'Body ')
			.replace('weight.', '')
			.replace('family.', ''),
	);

	switch (tokenOriginal.attributes.group) {
		case 'typography':
			const size = previewText.split(' ')[1] as keyof typeof typographyTokenNameMap;
			return previewText.replace(size, typographyTokenNameMap[size]);
		case 'fontWeight':
			return previewText;
		case 'fontFamily':
			return upperFirst(tokenOriginal.path[tokenOriginal.path.length - 1]);
	}
};

const Font = ({ tokenValue, tokenOriginal }: TypographyTokenCategoryProps) => {
	const styles = getFontStyles(tokenValue, tokenOriginal);
	const previewText = getPreviewText(tokenOriginal);

	return (
		<Box>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/design-system/use-primitives-text, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<p css={styles}>{previewText}</p>
		</Box>
	);
};

const borderStyles = xcss({
	borderStyle: 'solid',
	borderWidth: 'border.width',
	borderColor: 'color.border',
});

/**
 * UI for representing a BorderRadius token.
 */
const Radius = ({ value }: ColorTokenCategoryProps) => (
	<Box paddingInline="space.050" xcss={spaceWidthStyles}>
		<Box
			padding="space.150"
			xcss={borderStyles}
			style={{
				borderRadius: value,
			}}
		/>
	</Box>
);

/**
 * UI for representing a BorderWidth token.
 */
const BorderWidth = ({ value }: ColorTokenCategoryProps) => (
	<Box paddingInline="space.050" xcss={spaceWidthStyles}>
		<Box
			padding="space.150"
			xcss={borderStyles}
			style={{
				borderWidth: value,
			}}
		/>
	</Box>
);

export { Label, Color, Opacity, Elevation, Font, Space, Radius, BorderWidth };
