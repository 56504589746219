import React, {
	type MouseEvent,
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';

import { Pressable, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';

type ClickCopyAnchorProps = {
	href: string;
	children: any;
	testId?: string;
};

export const COPY_INFO = 'Copy link to heading';
export const COPY_COMPLETED = 'Copied!';
export const COPY_CONFIRM_TIMEOUT = 3000;

const copyButtonStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	display: 'flex',
	alignItems: 'center',
});

export const extractURLWithHash = (window: Window, newHash: string): string => {
	const fullURLWithHash = window.location.href;
	const existingHashIfAny = window.location.hash;
	const urlWithHash = fullURLWithHash.replace(existingHashIfAny, '') + newHash;
	return urlWithHash;
};

/**
 * __Click copy anchor__
 *
 * A click copy anchor allows a user to copy a URL to their clipboard.
 *
 */
const ClickCopyAnchor = ({ href, children, testId }: ClickCopyAnchorProps) => {
	const [popupText, setPopupText] = useState(COPY_INFO);
	const updateTooltip = useRef<() => void>();

	const memoizedPressableOnClick = useCallback(
		async (e: MouseEvent) => {
			const urlToCopy = extractURLWithHash(window, href);
			await navigator.clipboard.writeText(urlToCopy);
			setPopupText(COPY_COMPLETED);
		},
		[href],
	);

	// Required for keeping alignment when content changes
	useLayoutEffect(() => {
		updateTooltip.current?.();
	}, [popupText]);

	/* Dismiss copy confirm popup in 3s */
	useEffect(() => {
		if (popupText === COPY_COMPLETED) {
			const id = window.setTimeout(() => {
				setPopupText(COPY_INFO);
			}, COPY_CONFIRM_TIMEOUT);
			return () => window.clearTimeout(id);
		}
	}, [popupText]);

	return (
		<Tooltip
			// Use `update` render prop to update the tooltip position when content changes so that it's always centered above the target
			content={({ update }) => {
				updateTooltip.current = update;
				return popupText;
			}}
			position="top"
			testId={testId && `${testId}-tooltip`}
		>
			{(tooltipProps) => (
				<Pressable
					{...tooltipProps}
					onClick={memoizedPressableOnClick}
					xcss={copyButtonStyles}
					testId={testId && testId}
				>
					{children}
				</Pressable>
			)}
		</Tooltip>
	);
};

export default ClickCopyAnchor;
