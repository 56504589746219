import React from 'react';

import { Code } from '@atlaskit/code';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { Table, TBody, TD, TH, THead, TR } from '@atlaskit/table/primitives';
import { type ThemeState } from '@atlaskit/tokens';
import {
	typographyAdg3 as typographyAdg3Tokens,
	typography as typographyModernizedTokens,
} from '@atlaskit/tokens/tokens-raw';

import HorizontalScrollContainer from '../horizontal-scroll-container';

const fontWeightMap = {
	'400': 'Regular',
	'500': 'Medium',
	'600': 'Semibold',
	'700': 'Bold',
};

type TypographyValue = {
	previewText: string;
	tokenName?: string;
	fontSize?: string;
	lineHeight?: string;
	fontWeight?: number;
	paragraphSpacing?: string;
};

type TypographyTableProps = {
	values: TypographyValue[];
	isMonospace?: boolean;
	hasParagraphSpacing?: boolean;
	typographyTheme: ThemeState['typography'];
	testId?: string;
};

const monospaceStyles = xcss({
	font: 'font.code',
	fontFamily: 'font.family.code',
});

/**
 * __Typography table__
 */
export const TypographyTable = ({
	values,
	hasParagraphSpacing,
	typographyTheme,
	testId,
}: TypographyTableProps) => {
	return (
		<HorizontalScrollContainer>
			<Table testId={testId}>
				<THead>
					<TR isBodyRow={false}>
						<TH width="90px">Preview</TH>
						<TH width="175px">Token</TH>
						<TH width="120px">Font weight</TH>
						<TH width="145px">Font size</TH>
						<TH width="130px">Line height</TH>
						{hasParagraphSpacing && <TH width="180px">Paragraph spacing*</TH>}
					</TR>
				</THead>
				<TBody>
					{values.map((token) => {
						let fontSizeString,
							lineHeightString,
							paragraphSpacingString,
							fontWeightString,
							textComponent;
						const { tokenName, previewText, fontSize, lineHeight, fontWeight } = token;
						if (previewText === '-') {
							fontSizeString = lineHeightString = paragraphSpacingString = fontWeightString = '-';
							textComponent = <Text>{previewText}</Text>;
						} else if (tokenName && fontSize && lineHeight && fontWeight) {
							const tokenset =
								typographyTheme === 'typography-adg3'
									? typographyAdg3Tokens
									: typographyModernizedTokens;
							const tokenOriginal = tokenset.find((tOriginal) => tOriginal.name === tokenName);

							const isCode = tokenName.includes('code');
							const fontSizeValue = fontSize.slice(0, -2);
							fontSizeString = isCode
								? fontSize
								: `${Number(fontSizeValue) / 16} rem / ${fontSizeValue} px`;

							const lineHeightValue = lineHeight.slice(0, -2);
							lineHeightString = isCode
								? lineHeight
								: `${Number(lineHeightValue) / 16} rem / ${lineHeightValue} px`;

							const paragraphSpacingValue = token.paragraphSpacing
								? token.paragraphSpacing.slice(0, -2)
								: undefined;
							paragraphSpacingString = token.paragraphSpacing
								? `${Number(paragraphSpacingValue) / 16} rem / ${paragraphSpacingValue} px`
								: undefined;

							const fontWeightValue = fontWeight.toString() as keyof typeof fontWeightMap;
							fontWeightString = `${fontWeight} / ${fontWeightMap[fontWeightValue]}`;

							if (tokenName.includes('heading') || tokenName.includes('body')) {
								// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
								const fontStyles = { font: tokenOriginal?.value };
								textComponent = (
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
									<Box as="span" style={fontStyles}>
										{previewText}
									</Box>
								);
							} else if (tokenName.includes('code')) {
								textComponent = (
									<Box as="span" xcss={monospaceStyles}>
										{previewText}
									</Box>
								);
							}
						}

						return (
							<TR key={tokenName}>
								<TD>{textComponent}</TD>
								<TD>
									<Code>{tokenName}</Code>
								</TD>
								<TD>
									<Text>{fontWeightString}</Text>
								</TD>
								<TD>
									<Text>{fontSizeString}</Text>
								</TD>
								<TD>
									<Text>{lineHeightString}</Text>
								</TD>
								{hasParagraphSpacing && (
									<TD>
										<Text>{paragraphSpacingString}</Text>
									</TD>
								)}
							</TR>
						);
					})}
				</TBody>
			</Table>
		</HorizontalScrollContainer>
	);
};
