import React from 'react';

import { Code } from '@atlaskit/code';
import Heading from '@atlaskit/heading';
import { Grid, Inline, Stack, Text } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';

import { iconLabCoreIconImportPrefix } from '../helpers';
import { type IconData, type NewIconData } from '../types';

import IconCell from './icon-cell';

type BaseGridSectionProps = {
	testId?: string;
	importPath: string;
	heading: string;
	selectedIcon?: string;
};

type LegacyGridSectionProps = BaseGridSectionProps & {
	isLegacy: true;
	icons?: IconData[];
	onIconClick?: (icon: IconData) => void;
};

type GridSectionProps = BaseGridSectionProps & {
	isLegacy: false;
	icons?: NewIconData[];
	onIconClick?: (icon: NewIconData) => void;
};

const formatTitle = (title: string) => {
	let formatted = title.replace('-', ' '); // replace the hyphen in media-services with a space
	formatted = formatted.charAt(0).toUpperCase() + formatted.slice(1);
	return formatted;
};

/**
 * __Grid section__
 *
 * Component for a grid 'section'.
 * The Icon Explorer is made up of several grids separated by headings.
 * This grid section component consists of the grid as well as the heading for the section.
 *
 */
const GridSection = ({
	importPath,
	heading,
	testId,
	selectedIcon,
	...props
}: LegacyGridSectionProps | GridSectionProps) => {
	return props.icons ? (
		<Stack space="space.150" testId={testId}>
			<Inline space="space.150" alignBlock="baseline">
				<Heading size="medium">{formatTitle(heading)}</Heading>
				<Text color="color.text.subtle">{importPath}</Text>
			</Inline>
			{importPath === iconLabCoreIconImportPrefix && (
				<SectionMessage title="Icon Lab is subject to frequent change" appearance="warning">
					{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
					<p>
						Icon Lab contains experimental icons contributed by teams across Atlassian. The icons
						inside are subject to rapid change and removal.
					</p>
					{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
					<p>
						Marketplace partners should use icons once they're added to the core{' '}
						<Code>@atlaskit/icon</Code> package.
					</p>
				</SectionMessage>
			)}
			<Grid gap="space.100" templateColumns="repeat(auto-fill, minmax(120px, 1fr))">
				{props.isLegacy
					? props.icons.map((icon) => (
							<IconCell
								isLegacy={props.isLegacy}
								icon={icon}
								key={icon.componentName}
								onIconClick={props.onIconClick}
								isSelected={selectedIcon === icon.packageName}
							/>
						))
					: props.icons.map((icon) => (
							<IconCell
								isLegacy={props.isLegacy}
								icon={icon}
								key={icon.componentName}
								onIconClick={props.onIconClick}
								isSelected={selectedIcon === icon.packageName}
							/>
						))}
			</Grid>
		</Stack>
	) : null;
};

export default GridSection;
