import { type ShadowToken, token } from '@atlaskit/tokens';

const namedColors = ['transparent'];
const cssNamedColors: {
	[K in (typeof namedColors)[number]]: {
		r: number;
		g: number;
		b: number;
		a: number;
	};
} = {
	transparent: { r: 255, g: 255, b: 255, a: 0 },
} as const;

export const hexToRGBAValues = (hex: string) => {
	if (cssNamedColors[hex]) {
		return cssNamedColors[hex];
	}

	const hexColor = hex.replace('#', '');

	return {
		r: parseInt(hexColor.slice(0, 2), 16),
		g: parseInt(hexColor.slice(2, 4), 16),
		b: parseInt(hexColor.slice(4, 6), 16),
		a: parseFloat((parseInt(hexColor.slice(6, 8), 16) / 255).toFixed(2)),
	};
};

export const hexToRGBA = (hex: string) => {
	const { r, g, b, a } = hexToRGBAValues(hex);

	return `rgb${a ? 'a' : ''}(${r},${g},${b}${a ? `,${a}` : ''})`;
};

const getLuminance = ({ r, g, b }: { r: number; b: number; g: number }) => {
	const RsRGB = r / 255;
	const GsRGB = g / 255;
	const BsRGB = b / 255;

	const R = RsRGB <= 0.03928 ? RsRGB / 12.92 : Math.pow((RsRGB + 0.055) / 1.055, 2.4);
	const G = GsRGB <= 0.03928 ? GsRGB / 12.92 : Math.pow((GsRGB + 0.055) / 1.055, 2.4);
	const B = BsRGB <= 0.03928 ? BsRGB / 12.92 : Math.pow((BsRGB + 0.055) / 1.055, 2.4);

	return 0.2126 * R + 0.7152 * G + 0.0722 * B;
};

/**
 * Returns the contrast ratio [x]:1 based on W3C WCAG 2.x guidelines.
 *
 * @param luminance - Two relative luminance values, order doesn't matter.
 * Ratio will be the lightest luminance over darkest luminance
 */
const getRatio = (...luminance: [number, number]) => {
	const lighter = Math.max(...luminance);
	const darker = Math.min(...luminance);

	return (lighter + 0.05) / (darker + 0.05);
};
/**
 * Returns an accessible hard-coded text color based on the color contrast with
 * the background.
 *
 * @param hex - The Hex color code of the background
 * @param [opts][.hardcodedSurface] - If set, a design token will be returned instead
 * of a hard-coded color. This is to support more transparent backgrounds
 * to allow the text to invert colors depending on the current theme's surface color.
 */
export const getTextColorForBackground = (
	hex: string,
	opts?: { hardcodedSurface?: 'light' | 'dark' },
): string => {
	const { r, g, b, a } = hexToRGBAValues(hex);
	const bgLum = getLuminance({ r, g, b });
	const alphaLimit = 0.5; // not sure where value comes from but this provides the proper outcome
	const blackRatio = getRatio(bgLum, 0); // 0 being the relative luminance of #000000
	const whiteRatio = getRatio(1, bgLum); // 1 being the relative luminance of #FFFFFF

	const alphaConditionsPerSurface: {
		light: boolean;
		dark: boolean;
	} = {
		light: a < alphaLimit,
		dark: a > alphaLimit,
	};

	const alphaLimitExceeded =
		opts?.hardcodedSurface && alphaConditionsPerSurface[opts.hardcodedSurface];

	if (!opts?.hardcodedSurface && a < alphaLimit) {
		// This color is transparent, so the text will mainly cast onto the surface behind.
		// Needs to use tokens otherwise Dark mode would cause black text on black surface
		return token('color.text');
	}

	return (blackRatio > whiteRatio && !a) || (a && alphaLimitExceeded) ? 'black' : 'white';
};

/**
 * Returns a border if determined to be required based on the color contrast with
 * the background.
 *
 * @param value - The Hex color code of the value
 * @param background - The Hex color code of the background
 */
export const getBorderForValue = (value: string, background: string) => {
	const alphaLimit = 0.5;
	const relativeLuminanceLimit = 1.5;

	const rgba = hexToRGBAValues(value);
	const a = isNaN(rgba.a) ? 1 : rgba.a;
	const vl = getLuminance(rgba);
	const bl = getLuminance(hexToRGBAValues(background));
	const r = getRatio(vl, bl);

	return a < alphaLimit || r < relativeLuminanceLimit
		? `1px solid ${token('color.border')}`
		: undefined;
};

/**
 * Returns a box shadow formatted for CSS from a ShadowToken raw value.
 *
 * @param rawShadow - ShadowToken raw value
 */
export const getBoxShadow = (rawShadow: ShadowToken<string>['value']) =>
	rawShadow
		.map(({ radius, offset, color, opacity }) => {
			const { r, g, b } = hexToRGBAValues(color);

			return `${offset.x}px ${offset.y}px ${radius}px rgba(${r}, ${g}, ${b}, ${opacity})`;
		})
		.join(',');
