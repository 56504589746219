import React, { type ComponentType, useEffect, useState } from 'react';

import { IconButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import { type GlyphProps } from '@atlaskit/icon';
import CrossIcon from '@atlaskit/icon/core/close';
import ImageIcon from '@atlaskit/icon/core/image';
import migrationMap from '@atlaskit/icon/migration-map';
import Link from '@atlaskit/link';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { SimpleTag as Tag } from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';

import { getMigrationEndpoint } from '../helpers';
import { type NewIconData } from '../types';

import CodeCopyToClipboard from './code-copy-to-clipboard';
import CodeIconCopyToClipboard from './code-icon-copy-to-clipboard';

export type IconDetailsProps = Omit<BaseIconDetailsProps, 'legacyIcons'>;

export type BaseIconDetailsProps = {
	icon?: NewIconData;
	testId?: string;
	isModal?: boolean;
	onClose?: () => void;
	legacyIcons: LegacyIconDetails[];
};

const boxPanelStyles = xcss({
	borderColor: 'color.border',
	borderStyle: 'solid',
	borderRadius: '4px',
	borderWidth: 'border.width',
	width: '336px',
});

const closeButtonStyles = xcss({
	position: 'absolute',
	insetInlineEnd: 'space.200',
	insetBlockStart: 'space.200',
});

const sharedBoxBorderStyles = xcss({
	borderBlockEndColor: 'color.border',
	borderBlockEndStyle: 'solid',
	borderWidth: 'border.width',
});

type LegacyIconDetails = {
	name: string;
	icon: ComponentType<GlyphProps>;
	migrationImport: string;
};

const getLegacyIcon = (key: string) => {
	return import(
		/* webpackChunkName: "@atlaskit-internal_[request]" */ `@atlaskit/icon/glyph/${key}`
	);
};

const getCategorizationTagColor = (categorization: NewIconData['categorization']) => {
	if (categorization === 'multi-purpose') {
		return 'limeLight';
	} else if (categorization === 'single-purpose') {
		return 'purpleLight';
	} else if (categorization === 'utility') {
		return 'magentaLight';
	}
};

const getCategorizationTagText = (categorization: NewIconData['categorization']) => {
	if (categorization === 'multi-purpose') {
		return 'Multi purpose';
	} else if (categorization === 'single-purpose') {
		return 'Single purpose';
	} else if (categorization === 'utility') {
		return 'Utility';
	} else {
		return categorization;
	}
};

const getUsageText = (usage: string) => {
	const singlePurposePrefix = 'Single purpose - ';
	const multiPurposePrefix = 'Multi purpose - ';

	if (usage.startsWith(singlePurposePrefix)) {
		return usage.slice(singlePurposePrefix.length);
	} else if (usage.startsWith(multiPurposePrefix)) {
		return usage.slice(multiPurposePrefix.length);
	} else {
		return usage;
	}
};

/**
 * __Icon details__
 *
 * A panel which is populated when an icon is selected.
 * Contains information about the icon. *
 */
export const IconDetails = ({ icon, testId, isModal, onClose }: IconDetailsProps) => {
	const [legacyIcons, setLegacyIcons] = useState<LegacyIconDetails[]>([]);

	useEffect(() => {
		if (icon) {
			let legacyIcons: LegacyIconDetails[] = [];
			const allPromises: Promise<void>[] = [];
			Object.keys(migrationMap).forEach((key) => {
				const newIcon = migrationMap[key].newIcon;
				if (newIcon) {
					if (icon.packageName === `${newIcon.package}/${newIcon.type}/${newIcon.name}`) {
						const iconPromise = getLegacyIcon(key);

						if (iconPromise) {
							allPromises.push(iconPromise);
							iconPromise.then((legacyIcon) => {
								legacyIcons.push({
									name: key,
									icon: legacyIcon.default,
									migrationImport: `import ${icon.componentName} from '${getMigrationEndpoint(icon, key)}';`,
								});
							});
						}
					}
				}
			});

			Promise.all(allPromises).then(() => {
				setLegacyIcons(legacyIcons);
			});
		}
	}, [icon]);

	return (
		<BaseIconDetails
			icon={icon}
			testId={testId}
			legacyIcons={legacyIcons}
			isModal={isModal}
			onClose={onClose}
		/>
	);
};

export function BaseIconDetails({
	icon,
	testId,
	legacyIcons,
	isModal,
	onClose,
}: BaseIconDetailsProps) {
	return icon ? (
		<Box xcss={!isModal && boxPanelStyles}>
			<Stack testId={testId} grow="fill">
				<Box
					xcss={sharedBoxBorderStyles}
					backgroundColor="elevation.surface.sunken"
					paddingInline="space.600"
					paddingBlock="space.300"
				>
					<Inline alignInline="center">
						<icon.component label="" />
					</Inline>
					{isModal && (
						<Box xcss={closeButtonStyles}>
							<IconButton
								appearance="subtle"
								icon={CrossIcon}
								label="Close Modal"
								onClick={onClose}
							/>
						</Box>
					)}
				</Box>
				<Box xcss={sharedBoxBorderStyles} padding="space.300">
					<Stack space="space.150">
						<Inline alignBlock="center" space="space.050">
							<Heading size="medium">{icon.iconName}</Heading>
							<Box>
								<Tag
									text={getCategorizationTagText(icon.categorization)}
									color={getCategorizationTagColor(icon.categorization)}
								/>
							</Box>
						</Inline>
						{icon.usage && <Text>{getUsageText(icon.usage)}</Text>}
						<TagGroup label="keywords" alignment="start">
							{icon.keywords.map((keyword) => (
								<Tag text={keyword} />
							))}
						</TagGroup>
						<Stack space="space.100">
							<Heading size="small">Maintained by</Heading>
							<Text>{icon.team}</Text>
						</Stack>
					</Stack>
				</Box>
				<Box xcss={sharedBoxBorderStyles} padding="space.300">
					<Stack space="space.150">
						<Heading size="small">React</Heading>
						<CodeCopyToClipboard
							messages={{
								prompt: 'Copy import',
								success: 'Copied!',
								fail: 'Copy failed',
							}}
							value={`import ${icon.componentName} from '${icon.packageName}';`}
						>
							import {icon.componentName} from '{icon.packageName}';
						</CodeCopyToClipboard>
						<Link href="/components/icon/examples" target="_blank">
							Icon code examples
						</Link>
					</Stack>
				</Box>
				{legacyIcons?.length > 0 && (
					<Box xcss={sharedBoxBorderStyles} padding="space.300">
						<Stack space="space.150">
							<Heading size="small">Legacy icons (recommended fallbacks)</Heading>
							<Text>This icon replaces:</Text>
							<Stack space="space.0">
								{legacyIcons.map((legacy) => (
									<CodeIconCopyToClipboard
										name={legacy.name}
										icon={legacy.icon}
										value={legacy.migrationImport}
									/>
								))}
							</Stack>
							<Text as="p">
								Review size details in the{' '}
								<Link href="/components/icon/icon-legacy/migration-guide">migration guide</Link>.
							</Text>
						</Stack>
					</Box>
				)}
				<Box padding="space.300">
					<Stack space="space.150">
						<Heading size="small">Design assets</Heading>
						<Link
							href="https://www.figma.com/design/dpvtcht3y5zRH3edWgCZxt/%F0%9F%93%9A-ADS-Components?m=auto&node-id=10573-19668&t=td57HMYgZgZsGyxI-1"
							target="_blank"
						>
							Atlassian icon library (Atlassians only)
						</Link>
					</Stack>
				</Box>
			</Stack>
		</Box>
	) : (
		<Box
			xcss={boxPanelStyles}
			backgroundColor="elevation.surface.sunken"
			paddingInline="space.600"
			paddingBlock="space.300"
		>
			<Stack space="space.150" testId={testId} alignInline="center" grow="fill">
				<ImageIcon label="" color="currentColor" />
				<Text color="color.text.subtle" align="center">
					Select an icon for details
				</Text>
			</Stack>
		</Box>
	);
}
