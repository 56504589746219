import DrawerDefaultExample from "../../../../../../packages/design-system/drawer/examples/constellation/drawer-default";
import DrawerXcssExample from "../../../../../../packages/design-system/drawer/examples/constellation/drawer-xcss";
import DrawerCustomCompositionExample from "../../../../../../packages/design-system/drawer/examples/constellation/drawer-custom-composition";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_default from "!!raw-loader!../../../../../../packages/design-system/drawer/examples/constellation/drawer-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_xcss from "!!raw-loader!../../../../../../packages/design-system/drawer/examples/constellation/drawer-xcss";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_custom_composition from "!!raw-loader!../../../../../../packages/design-system/drawer/examples/constellation/drawer-custom-composition";
import * as React from 'react';
export default {
  DrawerDefaultExample,
  DrawerXcssExample,
  DrawerCustomCompositionExample,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_xcss,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHdrawer_custom_composition,
  React
};