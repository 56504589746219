import type { TransformedToken } from 'style-dictionary';

import palettesRaw from '@atlaskit/tokens/palettes-raw';

import { hexToRGBAValues } from './color-detection';

type PaletteCategory =
	| 'blue'
	| 'purple'
	| 'red'
	| 'magenta'
	| 'lime'
	| 'orange'
	| 'yellow'
	| 'green'
	| 'teal'
	| 'solid'
	| 'alpha'
	| 'light mode neutral'
	| 'dark mode neutral'
	| 'opacity';

type PaletteToken = {
	attributes: {
		group: 'palette';
		category: PaletteCategory;
	};
};

export type PaletteGroup = {
	name: 'colors' | 'light mode neutrals' | 'dark mode neutrals';
	subgroups: Array<{
		name: string;
		tokens: TransformedToken[];
	}>;
};

interface TransformedTokenPalette extends TransformedToken {
	attributes: TransformedToken['attributes'] & PaletteToken['attributes'];
}

/**
 * Find token type.
 */
const findGroup = (groups: PaletteGroup[], token: TransformedTokenPalette) => {
	const type = getGroupName(token);

	return groups.find(({ name }) => name === type);
};

/**
 * Find token subgroup.
 */
const findSubgroup = (subgroups: PaletteGroup['subgroups'], token: TransformedTokenPalette) => {
	const type = getSubgroupName(token);

	return subgroups.find(({ name }) => name === type);
};

const getSubgroupName = (token: TransformedTokenPalette): PaletteCategory => {
	let name: PaletteCategory;

	switch (token.attributes.category) {
		case 'light mode neutral':
		case 'dark mode neutral':
			const { a } = hexToRGBAValues(token.value);
			name = !a || a === 1 ? 'solid' : 'alpha';
			break;
		default:
			name = token.attributes.category;
			break;
	}

	return name;
};

const getGroupName = (token: TransformedTokenPalette): PaletteGroup['name'] => {
	let name: PaletteGroup['name'];
	switch (token.attributes.category) {
		case 'light mode neutral':
		case 'dark mode neutral':
			name = `${token.attributes.category}s` as PaletteGroup['name'];
			break;
		default:
			name = 'colors';
			break;
	}

	return name!;
};

/**
 * Add token to a group. Create new group if it does not exist.
 */
const addToGroup = ({
	groups,
	token,
}: {
	groups: PaletteGroup[];
	token: TransformedTokenPalette;
}) => {
	let matchingType = findGroup(groups, token);

	// No matching type, create new one
	if (!matchingType) {
		groups.push({
			name: getGroupName(token),
			subgroups: [],
		});
		matchingType = findGroup(groups, token);
	}

	if (matchingType) {
		let matchingSubgroup = findSubgroup(matchingType.subgroups, token);

		// No matching group, create new one and add token
		if (!matchingSubgroup) {
			matchingType?.subgroups.push({
				name: getSubgroupName(token),
				tokens: [],
			});

			matchingSubgroup = findSubgroup(matchingType.subgroups, token);
		}

		matchingSubgroup?.tokens.push(token);
	}
};

/**
 * Filter token palette to return only tokens that are colors
 */
const filterByColors = (token: TransformedTokenPalette) => token.attributes.category !== 'opacity';

const groupTokens = (tokens: TransformedTokenPalette[]) => {
	const filteredTokens = tokens.filter(filterByColors);

	// Group tokens
	let groupedTokens: PaletteGroup[] = [];

	filteredTokens.forEach((token) =>
		addToGroup({
			groups: groupedTokens,
			token,
		}),
	);

	return groupedTokens;
};

const groupedTokens = groupTokens(palettesRaw as unknown as TransformedTokenPalette[]);

export default groupedTokens;
