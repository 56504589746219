/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import WorldIcon from '@atlaskit/icon/glyph/world';
import { token, useThemeObserver } from '@atlaskit/tokens';
import { dark as darkTheme, light as lightTheme } from '@atlaskit/tokens/tokens-raw';

import { cleanTokenName } from '../../utils';
import type { Pairings as PairingsType, Token as TokenType } from '../data/types';

import CopyPasteBlock from './copy-paste-block';

const wrapperStyles = css({
	maxWidth: 'fit-content',
	padding: token('space.200', '16px'),
	border: `1px solid ${token('color.border')}`,
	borderRadius: token('border.radius', '3px'),
});

const tokenNameStyled = css({
	padding: `${token('space.050', '4px')} ${token('space.100', '8px')}`,
	background: token('color.background.neutral'),
	borderRadius: token('border.radius', '3px'),
	color: token('color.text'),
	cursor: 'pointer',
	font: token('font.code'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		verticalAlign: 'middle',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		background: token('color.background.neutral.hovered'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':active': {
		background: token('color.background.neutral.pressed'),
	},
});

const exampleStyles = css({
	display: 'flex',
	padding: '36px 104px',
	alignItems: 'center',
	columnGap: token('space.100', '8px'),
	borderRadius: token('border.radius', '3px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '24px',
	marginBlockEnd: token('space.200', '16px'),
});

const tokenNameWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:last-of-type)': {
		marginBlockEnd: token('space.100', '8px'),
	},
});
/**
 * __Pairing__
 *
 * Recommended pairings for the suggested token on the result panel.
 *
 */
const Pairing = ({ pairings }: { pairings: PairingsType }) => {
	const { colorMode } = useThemeObserver();
	const tokensList = colorMode === 'dark' ? darkTheme : lightTheme;
	const findTokenValue = (tokenName: string) => {
		const token = tokensList.find((token) => cleanTokenName(token.name) === tokenName) as TokenType;
		return token?.value;
	};

	return (
		<div css={wrapperStyles}>
			<div
				css={[
					exampleStyles,
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					{
						border: `1px solid ${findTokenValue(pairings.border)}`,
						background: findTokenValue(pairings.background),
						color: findTokenValue(pairings.text),
					},
				]}
			>
				<WorldIcon primaryColor={findTokenValue(pairings.icon)} label="pairingIcon" />
				Text
			</div>
			{Object.values(pairings)
				.sort()
				.map((tokenName) => (
					<div css={tokenNameWrapperStyles} key={tokenName}>
						<CopyPasteBlock
							text={tokenName}
							renderWrapper={(children) => <code css={tokenNameStyled}>{children}</code>}
						/>
					</div>
				))}
		</div>
	);
};

export default Pairing;
