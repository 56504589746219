import React, { useState } from 'react';

import CopyIcon from '@atlaskit/icon/core/copy';
import { Box, Inline, Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import CopyToClipboard, { type CopyToClipboardProps } from '../../copy-to-clipboard';

type CodeCopyButtonProps = {
	children: React.ReactNode;
	onClick: () => void;
};

const buttonStyles = xcss({
	textAlign: 'left',
	width: '100%',
	padding: 'space.0',
	borderRadius: 'border.radius.050',
	backgroundColor: 'color.background.neutral',
});

const copyIconStyles = xcss({
	visibility: 'hidden',
});

const containerStyles = xcss({
	flexGrow: 1,
	color: 'color.text',
	padding: 'space.100',
	font: 'font.code',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: token('space.250', '20px'),
});

/**
 * __Code copy__
 *
 * A code component which allows for a statement to be copied to the clipboard.
 */
const CodeCopyButton = ({ children, onClick }: CodeCopyButtonProps) => {
	const [isMouseOver, setIsMouseOver] = useState(false);

	const mouseEnterHandler = () => {
		setIsMouseOver(true);
	};

	const mouseLeaveHandler = () => {
		setIsMouseOver(false);
	};

	return (
		<Pressable
			xcss={buttonStyles}
			onMouseEnter={mouseEnterHandler}
			onMouseLeave={mouseLeaveHandler}
			onClick={() => onClick()}
		>
			<Inline>
				<Box xcss={containerStyles}>{children}</Box>
				<Box padding="space.075" xcss={!isMouseOver && copyIconStyles}>
					<CopyIcon label="" spacing="spacious" color="currentColor" />
				</Box>
			</Inline>
		</Pressable>
	);
};

type CodeCopyToClipboardProps = {
	value: string;
	children: React.ReactNode;
	messages: CopyToClipboardProps['messages'];
};

/**
 * __Code copy to clipboard__
 *
 * A component that allows you to copy code to the clipboard.
 */
const CodeCopyToClipboard = ({ value, children, messages }: CodeCopyToClipboardProps) => {
	return (
		<CopyToClipboard value={value} messages={messages} resetMs={2000}>
			{({ copy }) => <CodeCopyButton onClick={copy} children={children} />}
		</CopyToClipboard>
	);
};

export default CodeCopyToClipboard;
