import DrawerDefaultExample from "../../../../../../packages/design-system/drawer/examples/constellation/legacy-drawer/drawer-default";
import DrawerWidths from "../../../../../../packages/design-system/drawer/examples/constellation/legacy-drawer/drawer-widths";
import DrawerSurfaceDetection from "../../../../../../packages/design-system/drawer/examples/constellation/legacy-drawer/drawer-surface-detection";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlegacy_drawerSLASHdrawer_default from "!!raw-loader!../../../../../../packages/design-system/drawer/examples/constellation/legacy-drawer/drawer-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlegacy_drawerSLASHdrawer_widths from "!!raw-loader!../../../../../../packages/design-system/drawer/examples/constellation/legacy-drawer/drawer-widths";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlegacy_drawerSLASHdrawer_surface_detection from "!!raw-loader!../../../../../../packages/design-system/drawer/examples/constellation/legacy-drawer/drawer-surface-detection";
import * as React from 'react';
export default {
  DrawerDefaultExample,
  DrawerWidths,
  DrawerSurfaceDetection,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlegacy_drawerSLASHdrawer_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlegacy_drawerSLASHdrawer_widths,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlegacy_drawerSLASHdrawer_surface_detection,
  React
};