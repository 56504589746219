/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { createContext, Fragment, memo, useContext } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx, type SerializedStyles } from '@emotion/react';

import { Stack, Text } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

import type { TokenColumnWidth, TokenListColumns, TransformedTokenMerged } from '../types';
import { getTokenListColumns } from '../utils';

import NoResults from './no-results';
import TokenRow from './token-row';

const tableHeaderStyles = css({
	position: 'sticky',
	zIndex: 1,
	backgroundColor: token('elevation.surface'),
});

const tableStyles = css({
	display: 'flex',
	boxSizing: 'border-box',
	justifyContent: 'stretch',
	gap: token('space.200', '16px'),
	flexDirection: 'column',
	border: 'none',
});

const tableHeaderRowStyles = css({
	display: 'flex',
	padding: `${token('space.050', '4px')} 0`,
	gap: token('space.200', '16px'),
	borderBlockEnd: `${token('border.width', '2px')} solid ${token('color.border')}`,
});

const resetDefaultStyles = css({
	border: 'none',
});

const tableHeaderValueColumnStyles = css({
	padding: 0,
	[media.below.sm]: {
		display: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const tableTokenColumnWidthStyles: Record<TokenColumnWidth, SerializedStyles> = {
	medium: css({ minWidth: '5rem', maxWidth: '5rem' }),
	large: css({ minWidth: '8.75rem', maxWidth: '8.75rem' }),
	'extra-large': css({ minWidth: '11rem', maxWidth: '11rem' }),
};

const tableHeaderFirstColumn = css({
	width: '80%',
	padding: 0,
});

interface TokenListProps {
	/**
	 * Offset the sticky table header to avoid overlapping existing fixed elements.
	 */
	scrollOffset?: number;
	list?: TransformedTokenMerged[];
	isLoading?: boolean;
	isInSearchResult?: boolean;
	testId?: string;
	label?: string;
}

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export const TableColumnsContext = createContext<TokenListColumns>([]);

const TokenTableComponent = ({
	list,
	scrollOffset = 0,
	isLoading,
	isInSearchResult = false,
	testId,
	label,
}: TokenListProps) => {
	if (list !== undefined && list.length === 0) {
		return <NoResults />;
	}

	const group = list && list[0].attributes.group;
	const columns = getTokenListColumns(group);
	return (
		<TableColumnsContext.Provider value={columns}>
			<Stack space="space.200" testId={testId && `${testId}-token-list`}>
				<table css={tableStyles} aria-labelledby={label}>
					{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
					<thead css={[resetDefaultStyles, tableHeaderStyles, { top: scrollOffset }]}>
						<ListHeader />
					</thead>
					<tbody css={resetDefaultStyles}>
						{isLoading ? (
							<Fragment>
								<TokenRow key={1} isLoading />
								<TokenRow key={2} isLoading />
								<TokenRow key={3} isLoading />
								<TokenRow key={4} isLoading />
								<TokenRow key={5} isLoading />
							</Fragment>
						) : (
							list?.map((tokenItem) => (
								<TokenRow
									key={tokenItem.name}
									token={tokenItem}
									testId={testId}
									isInSearchResult={isInSearchResult}
								/>
							))
						)}
					</tbody>
				</table>
			</Stack>
		</TableColumnsContext.Provider>
	);
};

const ListHeader = () => {
	const columns = useContext(TableColumnsContext);
	return (
		<tr css={tableHeaderRowStyles}>
			<ColumnHeader columnName={columns[0].label} styles={tableHeaderFirstColumn} />
			{columns.slice(1).map(({ label, width = 'medium' }) => (
				<ColumnHeader
					key={label}
					columnName={label}
					styles={[tableHeaderValueColumnStyles, tableTokenColumnWidthStyles[width]]}
				/>
			))}
		</tr>
	);
};

interface ColumnHeaderProps {
	columnName: string;
	styles: SerializedStyles | SerializedStyles[];
}

const ColumnHeader = ({ columnName, styles }: ColumnHeaderProps) => (
	// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
	<th scope="col" css={styles}>
		<Text as="strong">{columnName}</Text>
	</th>
);

const TokenTable = memo(TokenTableComponent);

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default TokenTable;
