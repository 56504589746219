import React, { useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { SupportedLanguages } from '@atlaskit/code';
import CodeBlock from '@atlaskit/code/block';
import Heading from '@atlaskit/heading';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import { Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fireUIAnalytics } from '@atlassian/analytics-bridge';

import ErrorBoundary from '../error-boundary';
import replaceSrc from '../utils/replace-src';

import { ExampleActionContainer } from './actions';
import CodeSandbox, { type CodeSandboxProps } from './actions/code-sandbox';
import Copy from './actions/copy';
import CollapseButton from './collapse-button';
import ExampleCode from './example-code';
import ExampleShowcase, { type Background } from './example-showcase';

const EXAMPLE_HINT_HEIGHT = 200;
const LINE_TO_HEIGHT_FACTOR = 1.66667 * 12;

export interface ExampleProps {
	/**
	 * The component to render in the example
	 */
	Component: any;

	/**
	 * The source code for the component; if none is provided, will be generated
	 * from the `Component` rendered.
	 */
	source?: string;

	/**
	 * The language in which the code is written
	 */
	language?: string;

	/**
	 * The name of the package demonstrated in the example. Used to replace relative references
	 * in the example code
	 */
	packageName?: string;

	/**
	 * Comma and hyphen-separated list of lines to highlight in the source view
	 */
	highlight?: string;

	/**
	 * Height of the collapsed source view, in pixels
	 */
	hintHeight?: number;

	/**
	 * Controls the background color of the example showcase
	 */
	backgroundColor?: Background;

	/**
	 * Used for a11y labelling
	 */
	exampleName?: string;

	/**
	 * Manually provides the required information for CodeSandbox integration.
	 *
	 * If this is not provided and there are no meta attributes on the passed in `Component`
	 * then CodeSandbox integration will be disabled.
	 */
	sandboxInfo?: CodeSandboxProps;

	/**
	 * Toggle to show the CodeSandbox action. Even if `sandboxInfo` is provided, if
	 * `showCodeSandbox` is false, the button will be hidden. This enables consumers of
	 * gatsby-theme-brisk to simply turn the action on or off via theme shadowing
	 * without needing to know how the component works under the hood. See more:
	 * https://www.gatsbyjs.com/docs/how-to/plugins-and-themes/shadowing/#applying-new-props
	 */
	// eslint-disable-next-line @repo/internal/react/boolean-prop-naming-convention
	showCodeSandbox?: boolean;

	appearance?: 'showcase-and-source' | 'showcase-only' | 'source-only';
}

interface ExamplePropsWithComponents extends ExampleProps {
	components?: any;
}

const showcaseOnlyExampleActionContainerStyles = xcss({
	backgroundColor: 'color.background.neutral',
	padding: 'space.050',
	justifyContent: 'end',
	gap: 'space.050',
});

/**
 * __Example__
 *
 * An example renders code, along with the code as text.
 *
 */
export const Example = (props: ExamplePropsWithComponents) => {
	const {
		Component,
		language = 'tsx',
		highlight = '',
		hintHeight = EXAMPLE_HINT_HEIGHT,
		backgroundColor = 'checkered',
		showCodeSandbox = true,
		components,
		appearance = 'showcase-and-source',
	} = props;

	const [collapsed, setCollapsed] = useState(true);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const packageName = props.packageName ?? Component?.__pkgJSON?.name;
	const source = props.source ?? Component?.__raw ?? '';
	const transformedSource = transformSource(source, packageName);

	const showCollapseUI =
		transformedSource.split(/\r\n|\r|\n/).length > hintHeight / LINE_TO_HEIGHT_FACTOR;

	const exampleName = props.exampleName ?? Component?.__path?.replace?.(/^([^/]*\/)+/, '') ?? '';
	const exampleNameSpoken = exampleName.replace(/\-/g, ' ');

	let sandboxInfo: CodeSandboxProps | undefined;
	if (showCodeSandbox) {
		sandboxInfo = props.sandboxInfo ?? inferSandboxInfo(Component, exampleName, components);
	}

	const fireButtonAnalytics = () => {
		const analyticsEvent = createAnalyticsEvent({
			actionSubject: 'button',
			action: 'clicked',
		});
		const actionSubjectId = collapsed ? 'showMoreExample' : 'hideMoreExample';

		fireUIAnalytics(analyticsEvent, actionSubjectId, {
			exampleName,
			packageName,
		});
	};

	const isShowcaseVisible = appearance !== 'source-only';
	const isSourceVisible = appearance !== 'showcase-only';

	const actions = (
		<>
			{showCodeSandbox && sandboxInfo && <CodeSandbox {...sandboxInfo} />}
			<Copy source={transformedSource} />
		</>
	);

	return (
		<ExampleWrapper>
			{isShowcaseVisible && (
				<ExampleShowcase
					backgroundColor={backgroundColor}
					aria-label={`${exampleNameSpoken} example showcase`}
				>
					<ErrorBoundary
						fallback={<Heading size="small">Something went wrong loading this example.</Heading>}
					>
						<Component />
					</ErrorBoundary>
				</ExampleShowcase>
			)}

			{!isSourceVisible && (
				<Inline xcss={showcaseOnlyExampleActionContainerStyles}>{actions}</Inline>
			)}

			{isSourceVisible && (
				<>
					<ExampleCode
						collapsed={showCollapseUI ? collapsed : false}
						hintHeight={hintHeight}
						onMouseDown={() => setCollapsed(false)}
						aria-label={`${exampleNameSpoken} code example`}
						role="group"
					>
						<ExampleActionContainer>{actions}</ExampleActionContainer>
						<div id={`example-${exampleName}`}>
							<CodeBlock
								text={transformedSource}
								language={language as SupportedLanguages}
								showLineNumbers={false}
								highlight={highlight}
								shouldWrapLongLines={true}
							/>
						</div>
					</ExampleCode>
					{showCollapseUI && (
						<CollapseButton
							collapsed={collapsed}
							onClick={() => {
								setCollapsed(!collapsed);
								fireButtonAnalytics();
							}}
							aria-controls={`example-${exampleName}`}
						>
							{collapsed ? (
								<>
									{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
									<span
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className="dst-contrast-exempt"
										aria-label={`Show more, ${exampleNameSpoken} code example`}
									>
										Show more
									</span>
									<ChevronDownIcon label="" />
								</>
							) : (
								<>
									{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
									<span
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className="dst-contrast-exempt"
										aria-label={`Show less, ${exampleNameSpoken} code example`}
									>
										Show less
									</span>
									<ChevronUpIcon label="" />
								</>
							)}
						</CollapseButton>
					)}
				</>
			)}
		</ExampleWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExampleWrapper = styled.div({
	borderRadius: token('border.radius', '4px'),
	boxShadow: `0 0 0 1.2px ${token('color.border')}`,
	width: '100%',
});

const transformSource = (source: string, packageName: string): string => {
	let transformedSource = source;

	if (packageName) {
		transformedSource = replaceSrc(source, packageName);
	}

	transformedSource = transformedSource.trim();

	return transformedSource;
};

// '../../x/y/z' -> 'x/y/z'
const removeRelativePathInfo = (s: string) => s.replace(/^(\.+\/)*/, '');

const inferSandboxInfo = (
	Component: any,
	exampleName: string,
	components: any,
): CodeSandboxProps | undefined => {
	/**
	 * Check for the existence of properties added by `gatsby-theme-brisk/plugins/remark/add-raw-to-imports`
	 *
	 * If these aren't there then we cannot infer the sandbox info.
	 */
	if (!Component?.hasOwnProperty?.('__raw')) {
		return;
	}

	const pkgJSON = Component.__pkgJSON;

	const { groupId, packageId } = pkgJSON;
	const examplePath = `${groupId}/${packageId}/${removeRelativePathInfo(Component.__path)}.tsx`;

	const importReplacements: Array<[string, string]> = [
		[`${groupId}/${packageId}/src`, pkgJSON.name],
	];

	return {
		example: Component.__raw,
		examplePath,
		exampleName,
		importReplacements,
		pkgJSON: {
			...pkgJSON,
			devDependencies: {
				...Object.fromEntries(
					(components || []).map((component: any) => [component.name, component.version]),
				),
				...pkgJSON.devDependencies,
			},
		},
	};
};
