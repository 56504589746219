/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/* eslint-disable @repo/internal/styles/consistent-style-ordering */
import { type ReactNode, useEffect } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import Heading, { type HeadingProps } from '@atlaskit/heading';
import LinkIcon from '@atlaskit/icon/glyph/link';
import { Inline } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

import ClickCopyAnchor from '../click-copy-anchor';
import { useHeadings } from '../heading-context';

const sectionLinkStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		[media.above.md]: {
			// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
			marginInlineStart: token('space.negative.400', '-2rem'),
			padding: 0,
			opacity: 0,
			position: 'absolute',
			transform: 'translateX(4px)',
			transition: 'opacity 0.2s ease-out, transform 0.2s ease-out',
			'&:focus': {
				opacity: 1,
			},
		},
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		button: {
			opacity: 1,
			transitionDuration: '0.1s',
			transform: 'none',
		},
	},
	/**
	 * We want to apply vertical space between each section, so that content within a section is visually grouped, and sections
	 * have visual separation from each other. We can do this by applying a `margin-top` to `SectionLink`, however
	 * mainContentStyles already has a `padding-top: 32px;` which creates too much vertical space on the first element of MDX content
	 * As these section headers are wrapped in <div>s the standard CSS reset doesn't apply, so we handle the first-child differently here.
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
	marginBlockStart: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':first-child:is(div), style:first-child + :is(div)': {
		marginBlockStart: token('space.0', '0px'),
	},
});

type SupportedDepths = 2 | 3 | 4;

type SectionHeadingLinkProps = {
	id: string;
	children: ReactNode;
	clickToCopyTestId?: string;
	depth: SupportedDepths;
	/**
	 * An optional heading value, incase
	 * children is passed as an object
	 */
	heading?: string;
	css?: any;
};

const headingLevel: Record<SupportedDepths, Required<HeadingProps>['size']> = {
	2: 'large',
	3: 'medium',
	4: 'small',
};

/**
 * __SectionHeadingLink__
 *
 * A heading with a link icon that allows users to copy the link to the heading.
 * The heading will be added to the local navigation if it is a heading 2 or 3.
 */
const SectionHeadingLink = ({
	id,
	children,
	heading,
	depth,
	clickToCopyTestId,
}: SectionHeadingLinkProps) => {
	const { addHeading, removeHeading } = useHeadings();

	useEffect(() => {
		if (
			!children ||
			// Heading 4s are not added to the local navigation
			depth === 4
		) {
			return;
		}

		const payload = {
			id,
			value: heading || children,
			depth,
		};

		addHeading(payload);
		return () => {
			removeHeading(payload);
		};
	}, [addHeading, removeHeading, children, depth, id, heading]);

	return (
		<div id={id} css={sectionLinkStyles}>
			<Inline space="space.025" alignBlock="center">
				<ClickCopyAnchor href={`#${id}`} testId={clickToCopyTestId}>
					<LinkIcon
						label={`Copy link to '${heading || children?.toString()}'`}
						primaryColor={token('color.link')}
					/>
				</ClickCopyAnchor>
				<Heading size={headingLevel[depth]}>{children}</Heading>
			</Inline>
		</div>
	);
};

export default SectionHeadingLink;
