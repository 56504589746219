import React from 'react';

import CopyIcon from '@atlaskit/icon/glyph/copy';

import ExampleAction from './example-action';

interface CopyProps {
	source: string;
}

export const TOOLTIP_MESSAGE = {
	PROMPT: 'Copy code',
	SUCCESS: 'Copied!',
	FAILURE: 'Copy to clipboard failed',
};

/**
 * __Copy__
 *
 * A copy displays an icon to copy the example.
 *
 */
const Copy = ({ source }: CopyProps) => {
	const [tooltipMessage, setTooltipMessage] = React.useState(TOOLTIP_MESSAGE.PROMPT);

	const handleSuccess = React.useCallback(() => {
		setTooltipMessage(TOOLTIP_MESSAGE.SUCCESS);
	}, [setTooltipMessage]);

	const handleError = React.useCallback(() => {
		setTooltipMessage(TOOLTIP_MESSAGE.FAILURE);
	}, [setTooltipMessage]);

	const writeToClipboard = React.useCallback(() => {
		navigator.clipboard.writeText(source).then(handleSuccess, handleError);
	}, [source, handleSuccess, handleError]);

	const resetMessage = React.useCallback(() => {
		setTooltipMessage(TOOLTIP_MESSAGE.PROMPT);
	}, [setTooltipMessage]);

	const isVisible = Boolean(typeof window !== 'undefined' && navigator?.clipboard?.writeText);

	return isVisible ? (
		<ExampleAction
			icon={CopyIcon}
			label={tooltipMessage}
			onClick={writeToClipboard}
			onMouseOver={resetMessage}
			onFocus={resetMessage}
			aria-live="assertive"
			aria-atomic="true"
		/>
	) : null;
};

export default Copy;
