import React, { type ReactNode } from 'react';

import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import Tooltip from '@atlaskit/tooltip';

type ExampleActionProps = IconButtonProps & {
	/**
	 * Optional separate label for the tooltip. If unset, the tooltip has the label of the button.
	 */
	tooltipLabel?: string | ReactNode;
};

/**
 * __Example action__.
 *
 * An example action is a generic icon button displayed top right of an example.
 */
const ExampleAction = ({
	icon,
	label,
	tooltipLabel,
	testId,
	isLoading,
	...buttonProps
}: ExampleActionProps) => {
	const updateTooltip = React.useRef<() => void>();

	React.useLayoutEffect(() => {
		updateTooltip.current?.();
	}, [tooltipLabel, label]);

	return (
		<Tooltip
			content={({ update }) => {
				updateTooltip.current = update;
				return tooltipLabel || label;
			}}
			position="top"
			testId={testId ? `${testId}-tooltip` : undefined}
		>
			<IconButton
				isLoading={isLoading}
				appearance="subtle"
				icon={icon}
				testId={testId}
				label={label}
				// eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
				{...buttonProps}
			/>
		</Tooltip>
	);
};

export default ExampleAction;
