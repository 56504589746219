/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import upperFirst from 'lodash/upperFirst';

import { token } from '@atlaskit/tokens';

import { getTextColorForBackground, hexToRGBA } from '../color-detection';
import type { PaletteGroup } from '../grouped-tokens';
import { listItemStyleReset, listStyleReset } from '../index';

import CopyButton from './copy-button';

const subgroupsListStyles = css({
	display: 'flex',
	flexWrap: 'wrap',
	marginInlineEnd: `${token('space.negative.050', '-4px')}`,
	marginInlineStart: `${token('space.negative.050', '-4px')}`,
});

const listItemStyles = css({
	display: 'flex',
	padding: `${token('space.100', '8px')} ${token('space.100', '8px')} ${token(
		'space.100',
		'8px',
	)} ${token('space.200', '16px')}`,
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.token-alt-values': {
		transition: 'opacity 150ms',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover .token-alt-values, &:focus-within .token-alt-values': {
		opacity: 1,
	},
});

const copyButtonContainerStyles = css({
	display: 'inline-grid',
	gap: token('space.050', '3px'),
	gridTemplateColumns: '1fr 1fr',
	opacity: 0,
});

const subgroupStyles = (isNeutralColorGroup: boolean) =>
	css({
		minWidth: '50%',
		flex: '1 1 auto',
		marginBlockEnd: token('space.300', '24px'),
		paddingInlineEnd: token('space.050', '4px'),
		paddingInlineStart: token('space.050', '4px'),
		'@media (min-width: 1241px)': {
			minWidth: '33%',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			maxWidth: isNeutralColorGroup ? 'none' : '33%',
		},
	});

const subgroupTitleStyles = css({
	// Override c11n specificity issue
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'p&': {
		fontWeight: token('font.weight.bold'),
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		lineHeight: 1,
		marginBlockEnd: token('space.200', '16px'),
		marginBlockStart: token('space.300', '24px'),
	},
});

const subGroupStyles = css({
	borderRadius: token('border.radius', '3px'),
	paddingInlineEnd: token('space.300', '24px'),
	paddingInlineStart: token('space.300', '24px'),
});

const SubGroup = (group: PaletteGroup) => (
	<div
		css={[
			group.name.includes('neutral') && subGroupStyles,
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			group.name === 'light mode neutrals' && {
				// Must not use Design Tokens as the alpha tokens need to cast onto this
				// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
				backgroundColor: '#FAFBFC',
			},
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			group.name === 'dark mode neutrals' && {
				// Must not use Design Tokens as the alpha tokens need to cast onto this
				// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
				backgroundColor: '#161A1D',
			},
		]}
	>
		{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
		<ul css={[listStyleReset, subgroupsListStyles]}>
			{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
			{group.subgroups.map((subgroup) => (
				<li
					key={subgroup.name}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					css={[listItemStyleReset, subgroupStyles(group.name.includes('neutral'))]}
				>
					{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
					<p
						css={[
							subgroupTitleStyles,
							group.name === 'light mode neutrals' &&
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
								css({
									// Must not use Design Tokens as it casts onto a hardcoded surface
									// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
									color: '#172B4D',
								}),
							group.name === 'dark mode neutrals' &&
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
								css({
									// Must not use Design Tokens as it casts onto a hardcoded surface
									// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
									color: '#C7D1DB',
								}),
						]}
					>
						{upperFirst(subgroup.name)}
					</p>
					{subgroup.tokens.length > 0 ? (
						<ul
							css={[
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
								listStyleReset,
								// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
								{
									borderRadius: token('border.radius', '3px'),
									overflow: 'hidden',
								},
							]}
						>
							{subgroup.tokens.map((t) => {
								const tokenName = t.path[t.path.length - 1];

								const color = getTextColorForBackground(t.value, {
									hardcodedSurface: group.name.includes('neutral')
										? (group.name.replace(' mode neutrals', '') as 'light' | 'dark')
										: undefined,
								});

								return (
									<li
										key={t.value}
										css={[
											// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
											listItemStyleReset,
											listItemStyles,
											// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
											css({
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
												backgroundColor: t.value,
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
												color: color,
											}),
										]}
									>
										<CopyButton value={tokenName}>{tokenName}</CopyButton>
										<div
											// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
											className="token-alt-values"
											css={copyButtonContainerStyles}
										>
											<CopyButton value={t.value}>Hex</CopyButton>
											<CopyButton value={hexToRGBA(t.value)}>RGB</CopyButton>
										</div>
									</li>
								);
							})}
						</ul>
					) : null}
				</li>
			))}
		</ul>
	</div>
);

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default SubGroup;
