import React from 'react';

import Link from '@atlaskit/link';
import Lozenge, { type ThemeAppearance } from '@atlaskit/lozenge';
import SectionMessage, {
	SectionMessageAction,
	type SectionMessageProps,
} from '@atlaskit/section-message';

import SimpleMarkdownRenderer from '../simple-markdown-renderer';

interface StatusAction {
	href: string;
	text: string;
}

interface StatusMessage {
	title: string;
	description: string;
	appearance: ThemeAppearance;
}

type IStatus =
	| 'deprecated'
	| 'alpha'
	| 'open-alpha'
	| 'closed-beta'
	| 'beta'
	| 'intent-to-deprecate'
	| 'draft';

const statusMap: Record<IStatus, StatusMessage> = {
	// for backward compat
	draft: {
		title: 'Draft',
		appearance: 'default',
		description: 'This is draft content.',
	},
	// for backward compat
	'intent-to-deprecate': {
		title: 'Caution',
		appearance: 'moved',
		description:
			'We are planning on deprecating this package. We recommend using the {Y} component instead.',
	},
	deprecated: {
		title: 'Deprecated',
		appearance: 'removed',
		description:
			"This component has been deprecated, and we're removing it from our system. Reach out to us directly for migration assistance.",
	},
	alpha: {
		title: 'Early Access',
		appearance: 'moved',
		description:
			"This is an experiment. We may make frequent breaking changes with limited notice. We don't recommend using this without support from our team.",
	},
	// temporary - until new names are added to statuses.
	'open-alpha': {
		title: 'Early Access',
		appearance: 'moved',
		description:
			"This is an experiment. We may make frequent breaking changes with limited notice. We don't recommend using this without support from our team.",
	},
	// experimental - may not make sense.
	'closed-beta': {
		title: 'Beta',
		appearance: 'new',
		description: "New and ready to use. We'll provide comms and support for any major changes.",
	},
	beta: {
		title: 'Beta',
		appearance: 'new',
		description: "New and ready to use. We'll provide comms and support for any major changes.",
	},
};

const lozengeToSectionMessageAppearanceMap: Record<
	ThemeAppearance,
	SectionMessageProps['appearance']
> = {
	removed: 'error',
	moved: 'warning',
	new: 'discovery',
	success: 'success',
	inprogress: 'information',
	default: 'information',
};

export type StatusProps = {
	testId?: string;
	type: IStatus;
};

/**
 * __Status__
 *
 * Used to identify a common status for a component, library, or pattern.
 * Color is used consistently to ensure familiarity across contexts.
 *
 * @example
 * ```tsx
 * import { Status } from '@af/design-system-docs-ui'
 *
 * const MyStatus = () => <Status type="alpha" />
 * ```
 */
export const Status = ({ type, testId }: StatusProps) => {
	const { appearance, title } = statusMap[type];
	return (
		<Lozenge testId={testId} appearance={appearance}>
			{title}
		</Lozenge>
	);
};

export type StatusPanelProps = StatusProps & {
	/**
	 * optional additional text. Supports markdown.
	 */
	description?: string;
	/**
	 * optional additional CTAs
	 */
	actions?: StatusAction[];
};

/**
 * __Status panel__
 *
 * The panel is applied in the main component pages to indicate and explain a status.
 * Ideally these panels are placed in the first body slot after the title and description.
 *
 * @example
 * ```tsx
 * import { StatusPanel } from '@af/design-system-docs-ui'
 *
 * const Panel = () => <StatusPanel type="alpha" />
 * ```
 */
export const StatusPanel = ({
	type,
	testId,
	actions,
	description: userProvidedDescription,
}: StatusPanelProps) => {
	const { appearance, title, description } = statusMap[type];

	const sectionMessageAppearance = lozengeToSectionMessageAppearanceMap[appearance];

	if (!sectionMessageAppearance) {
		return null;
	}

	return (
		<SectionMessage
			testId={testId}
			title={title}
			appearance={sectionMessageAppearance}
			actions={
				actions &&
				actions.map((action) => (
					<SectionMessageAction key={action.text} href={action.href} linkComponent={Link}>
						{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
						<strong>{action.text}</strong>
					</SectionMessageAction>
				))
			}
		>
			<SimpleMarkdownRenderer>{userProvidedDescription || description}</SimpleMarkdownRenderer>
		</SectionMessage>
	);
};
